import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"

const BlogPostPage = ({ data, pageContext, location }) => {

  return (
        <Layout>
        <Seo
          pagetitle={data.microcmsBlog.title}
          pagedesc="desc"
          pagepath={location.pathname}
        />
        <div className="eyecatch">
          <figure>
            <img src={data.microcmsBlog.eyecatch.url} alt="" />
          </figure>
        </div>
        <article className="content">
          <div className="container">
            <h1 className="bar">{data.microcmsBlog.title}</h1>
            <aside className="info">
              <time dateTime={data.microcmsBlog.publishedAt}><i className="far fa-clock" />{data.microcmsBlog.publishDate}</time>
              <div className="cat">
                <i className="far fa-folder-open" />
                <ul>
                  {data.microcmsBlog.category.map(cat => (
                    <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                  ))}
                </ul>
              </div>
            </aside>
            <div className="postbody"
              dangerouslySetInnerHTML={{ __html: data.microcmsBlog.content }}
              >
            </div>
            <ul className="postlink">
              {pageContext.previous && (
                <li className="prev">
                  <Link to={`/blog/post/${pageContext.previous.slug}/`} rel="prev">
                    <i className="fas fa-chevron-left" />
                    <span>{pageContext.previous.title}</span>
                  </Link>
                </li>
              )}
              {pageContext.next && (
                <li className="next">
                  <Link to={`/blog/post/${pageContext.next.slug}/`} rel="next">
                    <span>{pageContext.next.title}</span>
                    <i className="fas fa-chevron-right" />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </article>
        </Layout>
  )
}

export const query = graphql`
query($id: String!) {
  microcmsBlog(id: {eq:$id}) {
    content
    slug
    title
    category {
      categorySlug
      category
      id
    }
    eyecatch {
      height
      url
      width
    }
    publishDate(formatString: "YYYY年MM月DD日")
    publishedAt(formatString: "YYYY-MM-DD")
  }
}`

export default BlogPostPage